import React, { useState, useEffect } from 'react'
import { Waypoint } from 'react-waypoint'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { staggeredFadeInUp } from '../animation/animations'

import Title from '../title'
import CrossSvg from '../../images/table-cross.svg'

export const fragment = graphql`
  fragment PricingFragment on wordpress__PAGEAcf {
    pricing {
      overview
      pricingTable {
        characteristicsDescription
        characteristicsTitle
        commitment
        serviceDescription
        serviceTitle
      }
      title
      backgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const Pricing = ({ title, overview, backgroundImage, pricingTable }) => {
  const [animation, setAnimation] = useState(undefined)

  // Ensure intial animation are fired on page load
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 500)
    }
  }, [])

  const handleAnimation = () => {
    setAnimation(true)
  }

  return (
    <Outer animate={animation ? 'visible' : 'hidden'} variants={staggeredFadeInUp.parent}>
      <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="25%" />
      <Inner className="container">
        <Title text={title} />
        <Description variants={staggeredFadeInUp.child}>{overview}</Description>
        <Table>
          <TableTitle variants={staggeredFadeInUp.child}>Service</TableTitle>
          <TableTitle variants={staggeredFadeInUp.child}>Customer Characteristics</TableTitle>
          <TableTitle variants={staggeredFadeInUp.child}>Commitment</TableTitle>
          {pricingTable.map((service, i) => (
            <React.Fragment key={i}>
              <TableBox variants={staggeredFadeInUp.child}>
                <TableBoxTitle>{service.serviceTitle}</TableBoxTitle>
                <TableBoxDescription>{service.serviceDescription}</TableBoxDescription>
              </TableBox>
              <TableGap>
                {i === 1 && (
                  <motion.div variants={staggeredFadeInUp.child}>
                    <Cross />
                  </motion.div>
                )}
              </TableGap>
              <TableBox variants={staggeredFadeInUp.child}>
                <TableBoxTitle>{service.characteristicsTitle}</TableBoxTitle>
                <TableBoxDescription>{service.characteristicsDescription}</TableBoxDescription>
              </TableBox>
              <TableGap>
                {i === 1 && (
                  <motion.div variants={staggeredFadeInUp.child}>
                    <Cross />
                  </motion.div>
                )}
              </TableGap>
              <TableBox variants={staggeredFadeInUp.child}>
                <TableBoxTitle>{service.commitment}</TableBoxTitle>
              </TableBox>
            </React.Fragment>
          ))}
        </Table>
        <TableMobile>
          <TableTitleMobile>Service</TableTitleMobile>
          {pricingTable.map((service, i) => (
            <React.Fragment key={i}>
              <TableBox>
                <TableBoxTitle>{service.serviceTitle}</TableBoxTitle>
                <TableBoxDescription>{service.serviceDescription}</TableBoxDescription>
              </TableBox>
            </React.Fragment>
          ))}
          <TableGapMobile>
            <Cross />
          </TableGapMobile>
          <TableTitleMobile>Customer Characteristics</TableTitleMobile>
          {pricingTable.map((service, i) => (
            <React.Fragment key={i}>
              <TableBox>
                <TableBoxTitle>{service.characteristicsTitle}</TableBoxTitle>
                <TableBoxDescription>{service.characteristicsDescription}</TableBoxDescription>
              </TableBox>
            </React.Fragment>
          ))}
          <TableGapMobile>
            <Cross />
          </TableGapMobile>
          <TableTitleMobile>Commitment</TableTitleMobile>
          {pricingTable.map((service, i) => (
            <React.Fragment key={i}>
              <TableBox>
                <TableBoxTitle>{service.commitment}</TableBoxTitle>
              </TableBox>
            </React.Fragment>
          ))}
        </TableMobile>
      </Inner>
      <Image
        fluid={backgroundImage.localFile.childImageSharp.fluid}
        style={{ position: 'absolute' }}
      />
    </Outer>
  )
}

const Outer = styled(motion.section)`
  position: relative;
  width: 100%;
  padding: 15rem 0;
`

const Inner = styled(motion.div)`
  width: 100%;

  h2 {
    position: relative;
    font-size: 6.6rem;
    line-height: 1.4;
    text-align: center;
    z-index: 5;

    @media (max-width: 650px) {
      font-size: 4rem;
    }
  }
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
`

const Description = styled(motion.p)`
  position: relative;
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  text-align: center;
  max-width: 63rem;
  margin: 2rem auto 5rem;
  z-index: 5;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const TableMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;

  @media (min-width: 650px) {
    display: none;
  }
`

const Table = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 5;

  @media (max-width: 650px) {
    display: none;
  }
`

const TableTitle = styled(motion.p)`
  width: 29%;
  font-size: 2.8rem;
  text-align: center;
  padding: 0;
  white-space: nowrap;

  @media (max-width: 1000px) {
    font-size: 2rem;
    font-weight: 600;
  }

  @media (max-width: 700px) {
    white-space: normal;
  }

  @media (max-width: 650px) {
    display: none;
  }
`

const TableTitleMobile = styled.p`
  display: none;
  width: 100%;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  padding: 0;

  @media (max-width: 650px) {
    display: block;
  }
`

const TableBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 29%;
  text-align: center;
  padding: 1.5rem 3rem;
  border: 1px solid ${props => props.theme.colours.secondary};
  margin-bottom: 1.5rem;

  @media (max-width: 650px) {
    width: 80%;
  }
`
const TableBoxTitle = styled.p`
  display: block;
  font-family: ${props => props.theme.fonts.san};
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
`
const TableBoxDescription = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const Cross = styled(CrossSvg)`
  width: 2.2rem;
  height: 2.2rem;
`

const TableGap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5%;
  margin-bottom: 1.5rem;

  @media (max-width: 650px) {
    display: none;
  }
`

const TableGapMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 3.5rem;
  width: 100%;

  @media (max-width: 650px) {
    display: flex;
  }

  &:last-child {
    display: none;
  }
`

export default Pricing
