import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const titleStagger = {
  parent: {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.02,
        delayChildren: 0.3
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  },
  child: {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        duration: 1,
        ease: 'circOut'
      }
    },
    hidden: {
      opacity: 0,
      y: 30
    }
  }
}

const Title = ({ type, text }) => {
  // Split the text string into an array of words
  const splitText = text.split(' ')

  const [animation, setAnimation] = useState(undefined)

  const handleAnimation = () => {
    setAnimation(true)
  }

  switch (type) {
    case 'h1':
      return (
        <>
          <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
          <motion.h1 animate={animation ? 'visible' : 'hidden'} variants={titleStagger.parent}>
            {splitText.map((word, wordCount) => {
              const chars = word.split('')
              return (
                <Word key={wordCount}>
                  {chars.map((char, charCount) => (
                    <React.Fragment key={charCount}>
                      <Char variants={titleStagger.child}>{char}</Char>
                    </React.Fragment>
                  ))}
                  &nbsp;
                </Word>
              )
            })}
          </motion.h1>
        </>
      )
    case 'h2':
      return (
        <>
          <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
          <motion.h2 animate={animation ? 'visible' : 'hidden'} variants={titleStagger.parent}>
            {splitText.map((word, wordCount) => {
              const chars = word.split('')
              return (
                <Word key={wordCount}>
                  {chars.map((char, charCount) => (
                    <React.Fragment key={charCount}>
                      <Char variants={titleStagger.child}>{char}</Char>
                    </React.Fragment>
                  ))}
                  &nbsp;
                </Word>
              )
            })}
          </motion.h2>
        </>
      )
    case 'h3':
      return (
        <>
          <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
          <motion.h3 animate={animation ? 'visible' : 'hidden'} variants={titleStagger.parent}>
            {splitText.map((word, wordCount) => {
              const chars = word.split('')
              return (
                <Word key={wordCount}>
                  {chars.map((char, charCount) => (
                    <React.Fragment key={charCount}>
                      <Char variants={titleStagger.child}>{char}</Char>
                    </React.Fragment>
                  ))}
                  &nbsp;
                </Word>
              )
            })}
          </motion.h3>
        </>
      )
    case 'p':
      return (
        <>
          <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
          <motion.p animate={animation ? 'visible' : 'hidden'} variants={titleStagger.parent}>
            {splitText.map((word, wordCount) => {
              const chars = word.split('')
              return (
                <Word key={wordCount}>
                  {chars.map((char, charCount) => (
                    <React.Fragment key={charCount}>
                      <Char variants={titleStagger.child}>{char}</Char>
                    </React.Fragment>
                  ))}
                  &nbsp;
                </Word>
              )
            })}
          </motion.p>
        </>
      )
    default:
      return null
  }
}

export default Title

Title.defaultProps = {
  type: 'h2',
  text: ''
}

const Word = styled.span`
  display: inline-block;
`

const Char = styled(motion.span)`
  display: inline-block;
  opacity: 0;
`
